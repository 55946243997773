/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Obl, Headline, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Atopic Dermatitis, or ", React.createElement(_components.strong, null, "Eczema"), ", is a common condition that causes the skin to become ", React.createElement(_components.strong, null, "inflamed, itchy, scaly, and dry"), ". Eczema can flare up every now and then and can be difficult to treat, but is not contagious. Eczema is often called \"the itch that rashes\". Itching may begin before the rash appears. ", React.createElement(_components.strong, null, "Scratching makes flare ups worse"), ".")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Symptons Include:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Dry skin"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Itching, which may be severe, especially at night"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Brownish-gray, purple patches, especially on the hands, feet, ankles, wrists, neck, upper chest, eyelids, inside the bend of the elbows and knees, and in infants, the face and scalp"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Small, raised bumps, which may leak fluid and crust over when scratched"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Thickened, cracked, scaly skin"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Raw, sensitive, swollen skin from scratching"), "\n"), "\n"), React.createElement(_components.p, null, "For more info - ", React.createElement(Obl, {
    to: "https://www.mayoclinic.org/diseases-conditions/atopic-dermatitis-eczema/symptoms-causes/syc-20353273",
    title: "Mayo Clinic explanation fo eczema"
  }, "https://www.mayoclinic.org/diseases-conditions/atopic-dermatitis-eczema/symptoms-causes/syc-20353273"))), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Causes"), React.createElement(_components.p, null, "Healthy skin helps retain moisture and protects you from bacteria, irritants and allergens. Eczema is related to a gene variation that affects the skin's ability to provide this protection. This allows your skin to be affected by environmental factors, irritants and allergens such as soaps, fragrances, overly dry or damp weather, pollen, and pets.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Eczema commonly appears on ", React.createElement(_components.strong, null, "parts of the body that tend to crease,"), " such as  and the insides of the elbows...")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "...and the back of the knees.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Eczema can look different depending on the person, but in black individuals it is common for it to appear more ", React.createElement(_components.strong, null, "brown, purple, or gray"), " in color, rather than red.")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "hlZIjzygkEI",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more how you manage eczema.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatments"), React.createElement(_components.p, null, "The following tips may help prevent bouts of dermatitis (flares) and minimize the drying effects of bathing:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "Moisturize your skin at least twice a day."), " ", React.createElement(Obl, {
    to: "https://amzn.to/3guJzgj",
    title: "Amazon Affiliate Link"
  }, "Creams, ointments and lotions"), " seal in moisture. Choose a product or products that work well for you."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "Take shorter baths or showers."), " Limit your baths and showers to 10 to 15 minutes. And use warm, rather than hot, water."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "Use ", React.createElement(Obl, {
    to: "https://amzn.to/3XnTNQj",
    title: "Amazon Affiliate Link"
  }, "gentle/mild soaps"), "."), " Deodorant soaps and antibacterial soaps can remove more natural oils and dry your skin."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "Dry yourself carefully."), " After bathing gently pat your skin dry with a ", React.createElement(Obl, {
    to: "https://amzn.to/3TW70gg",
    title: "Amazon Affiliate Link"
  }, "soft towel"), " and apply ", React.createElement(Obl, {
    to: "https://amzn.to/3gnQE2g",
    title: "Amazon Affiliate Link"
  }, "moisturizer"), " while your skin is still damp."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Don't scratch; it provokes rash"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "For more help, contact a dermatologist."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
